<template>
    <div>
      <div style="width: 6.5104rem;margin: 0 auto;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product-center'}">yth206官网首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/product-center', query: { tab: 'first' }}">交通雷达</el-breadcrumb-item>
          <el-breadcrumb-item>边缘计算服务器</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
  
      <div style="display: flex;justify-content: center;margin-top: .4167rem;">
        <div  style="display: inline-block;transform: scale(1.05) translateX(-0.6771rem);">
          <img src="../../assets/009.webp" alt="" style="">
        </div>
  
        <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
          <p class="name">边缘计算服务器</p>
          <P class="content" style="text-indent: .1198rem;font-size: .0833rem">边缘计算服务器是一种分布式计算架构，将计算资源和数据处理能力尽可能地靠近数据源和终端设备，以提供更快速、可靠和安全的计算服务。
            与传统的云计算模式相比，边缘计算服务器将计算任务从中心化的云端转移到接近数据源的边缘节点上进行处理,提供更快速、可靠和安全的计算服务，适用于需要低延迟、高安全性和大规模数据处理的应用场景。
          </P>
          <div style="margin-top: .2604rem;">
            <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
              <icon class="el-icon-phone-outline"></icon>技术热线
            </div>
            <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>
  
          </div>
  
  
        </div>
  
  
  
      </div>
  
      <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
        详细参数</div>
  
      <!--  style="width: 6.5104rem;margin: 0 auto;"-->
      <div class="msgList" style=" width: 6.5104rem; margin: 0 auto;">
        <ul>
          <li>
            <div class="txt"><em>CPU</em></div>
            <div class="msg">6核NVIDIA Carmel ARM®v8.2 64位CPU 6MB L2 + 4MB L3</div>
          </li>
          <!-- ≥   ≤ -->
          <li>
            <div class="txt"><em>GPU</em></div>
            <div class="msg">具有48个Tensor内核的384核NVIDIA VoltaTM GPU</div>
          </li>
          <li>
            <div class="txt"><em>系统内存</em></div>
            <div class="msg">8 GB 128位LPDDR4x @ 1600 MHz 51.2GB/s</div>
          </li>
          <li>
            <div class="txt"><em>存储</em></div>
            <div class="msg">16 GB eMMC 5.1</div>
          </li>
          <li>
            <div class="txt"><em>显示接口</em></div>
            <div class="msg">HDMI Type A x1</div>
          </li>
          <li>
            <div class="txt"><em>以太网</em></div>
            <div class="msg">GbE局域网x 2(GbE PD x1+GbE局域网x 1)</div>
          </li>

          <li>
            <div class="txt"><em>尺寸</em></div>
            <div class="msg">189*96.7*57.5mm</div>
          </li>

          <li>
            <div class="txt"><em>工作温度</em></div>
            <div class="msg">-25°C~ 60°C 满载运行; 60°C~70°C 降频运行</div>
          </li>
       
        </ul>
      </div>
  
  
      <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
        <el-button @click="goBack">返回列表</el-button>
      </div>
  
  
    </div>
  
  
  
  
  </template>
  <script>
  
  
    export default {
      methods: {
        goBack() {
  this.$router.push({ path: '/product-center', query: { tab: 'first' }});
  const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

}
        ,
  
        //       goTo(){
        //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
        //     const windowHeight = window.innerHeight; // 
        //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
        //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
        //     window.scrollTo(0, scrollTo); //
        //   },
      }
    }
  
  
  
  
  </script>
  
  <style scoped>

    .name {
      padding-bottom: .1042rem;
      border-bottom: #eeeeee solid .0052rem;
      font-size: .1042rem;
      color: #2056c0;
      font-weight: bold;
      position: relative;
    }
  
    .msgList {
      padding-top: .2604rem;
      border-bottom: #eee solid .0052rem;
    }
  

    .msgList li {
      border: #c6c6c6 solid .0052rem;
      padding: .0521rem .1042rem .0521rem 1.0938rem;
      position: relative;
      font-size: .0729rem;
      margin-bottom: .0521rem;
      text-align: center;
      line-height: .2604rem;
    }

  
    li {
      list-style-position: outside;
      list-style-type: none;
    }

  
    .msgList li .msg {
      line-height: .1667rem;
      color: #999;
    }
  
    .content {
      padding-top: .1563rem;
      font-size: .0729rem;
      line-height: .125rem;
      color: #434343;
  
    }
  
    .tel {
      padding-top: .0521rem;
      font-size: .1875rem;
      color: #bb3944;
      font-family: Impact;
    }
  
    .txt {
      position: absolute;
      left: 0rem;
      top: 0rem;
      width: .9896rem;
      height: 100%;
      text-align: center;
      color: #FFFFFF;
      background: #2056c0;
      font-size: .0729rem;
      font-family: Arial, "微软雅黑";
  
    }
  
    .tittle {
  
      margin-top: .5208rem;
      height: .2083rem;
      text-align: center;
      line-height: .2083rem;
      font-weight: bold;
      font-size: .0833rem;
      color: #fff;
  
      position: relative;
    }
  </style>
  <!--  
  有一个轮播图, 




   -->