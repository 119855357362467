<template>
  <div>
    <div style="width: 6.5104rem;margin: 0 auto;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/product-center'}">yth206官网首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/product-center', query: { tab: 'third' }}"> 可变情报板系列 </el-breadcrumb-item>
        <el-breadcrumb-item>LED交通诱导屏</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div style="display: flex;justify-content: center;margin-top: .4167rem;">
      <div>
        <img src="../../assets/kbxx6.webp" alt="" style="height: 2.3438rem;">
      </div>

      <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
        <p class="name">LED交通诱导屏</p>
        <P class="content" style="text-indent: .1198rem;font-size: .0833rem"> 交通综合LED诱导屏是由“图形道路标志板+镶嵌LED可变光带+可变LED文字显示屏"组合而成的动静态结合的多功能信息显示屏。产品通过颜色区别文字信息，提供复杂路段的交通信息，具有显示形象直观、信息量大、节能综合性价比高等特点，适用于城市道路和公路主要干线的路网密集地段，现广泛应用于城市智能交通诱导系统中。
        </P>
        <div style="position: absolute;bottom: .1563rem;">
          <div style="position: relative;
                  padding-left: .1302rem;
                  font-size: .0729rem;
                  color: #2056c0;">
            <icon class="el-icon-phone-outline"></icon>技术热线
          </div>
          <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>

        </div>


      </div>



    </div>

    <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
      详细参数</div>

    <!--  style="width: 6.5104rem;margin: 0 auto;"-->
    <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
      <ul>
        <li>
          <div class="txt"><em>型号</em></div>
          <div class="msg">YDBZ-0-B-IFKJ-n</div>
        </li>
        <li>
          <div class="txt"><em>像素点间距</em></div>
          <div class="msg">10mm、16mm</div>
        </li>
        <li>
          <div class="txt"><em>像素组成</em></div>
          <div class="msg">1R1G、2R1G</div>
        </li>
        <li>
          <div class="txt"><em>安装方式</em></div>
          <div class="msg">悬臂式、立柱式、门架式等</div>
        </li>
        <li>
          <div class="txt"><em>发光亮度</em></div>
          <div class="msg">全彩≥10000cd/㎡，双基色≥8000cd/㎡</div>
      </li>
        <li>
          <div class="txt"><em>显示方式</em></div>
          <div class="msg">显示简易图形、文字、数字及全黑</div>
        </li>
        <li>
          <div class="txt"><em>显示有效尺寸</em></div>
          <div class="msg">文字480*480mm、灯带宽度128mm宽度</div>
        </li>
        <li>
          <div class="txt"><em>抗风强度</em></div>
          <div class="msg">≥40m/s</div>
        </li>
        <li>
          <div class="txt"><em>刷新频率</em></div>
          <div class="msg">≥100Hz</div>
        </li>
        
      </ul>
    </div>


    <div style="display: flex;justify-content: center; margin-top: .1302rem; margin-bottom: .1302rem; ">
      <el-button @click="goBack">返回列表</el-button>
    </div>


  </div>




</template>
<script>


  export default {
    methods: {
      goBack() {
  this.$router.push({ path: '/product-center', query: { tab: 'third' }});
  const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

}
      ,

      //       goTo(){
      //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
      //     const windowHeight = window.innerHeight; // 
      //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
      //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
      //     window.scrollTo(0, scrollTo); //
      //   },
    }
  }




</script>

<style scoped>
  .name {
    padding-bottom: .1042rem;
    border-bottom: #eeeeee solid .0052rem;
    font-size: .1042rem;
    color: #2056c0;
    font-weight: bold;
    position: relative;
  }

  .msgList {
    padding-top: .2604rem;

    border-bottom: #eee solid .0052rem;
  }

  .msgList li {
    border: #c6c6c6 solid .0052rem;
    padding: .0521rem .1042rem .0521rem 1.0938rem;
    position: relative;
    font-size: .0729rem;
    margin-bottom: .0521rem;
    text-align: center;
    line-height: .2604rem;
  }

  li {
    list-style-position: outside;
    list-style-type: none;

  }

  .msgList li .msg {
    line-height: .1667rem;
    color: #999;
  }

  .content {
    padding-top: .1563rem;
    font-size: .0729rem;
    line-height: .125rem;
    color: #434343;

  }

  .tel {
    padding-top: .0521rem;
    font-size: .1875rem;
    color: #bb3944;
    font-family: Impact;
  }

  .txt {
    position: absolute;
    left: 0rem;
    top: 0rem;
    width: .9896rem;
    height: 100%;
    text-align: center;
    color: #FFFFFF;
    background: #2056c0;
    font-size: .0729rem;
    font-family: Arial, "微软雅黑";

  }

  .tittle {

    margin-top: .5208rem;
    height: .2083rem;
    text-align: center;
    line-height: .2083rem;
    font-weight: bold;
    font-size: .0833rem;
    color: #fff;

    position: relative;
  }
</style>