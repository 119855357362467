<template>
  <div class="contain">
    <div :style="{ width: '100%', height: '3.3333rem', backgroundImage: 'url(' + productCenterImage  + ')', backgroundSize: '100% 100%' }">
     
    </div>

    <div
      style="width: 100%; height: .6823rem; display: flex; justify-content: center; align-items: center; position: relative;">
      <img src="../assets/Product Center.png" alt="" style="height: .1875rem;">
      <p
        style="font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .1042rem;">
        yth206官网首页
      </p>
      <p
        style=" display: inline-block; height: .0104rem; width: .3333rem;background-color:  #407BF5;;font-size: .1667rem; color: #5B5B5B; font-family: PingFang SC, PingFang SC; font-weight: bold; position: absolute; bottom: .0521rem;">
      </p>
    </div>

    <router-view @tabChanged="changeTab"> </router-view>

  </div>
</template>

<script>
  export default {
    name: 'ProductCenter',
    data() {
      return {
        value2: 'first',
        productCenterImage: require('../assets/pood1.webp')

      };
    },

    methods: {
      changeTab(newValue) {
        this.value2 = newValue;
        if (newValue === 'first') {
          this.productCenterImage = require('../assets/pood1.webp');
        } else if (newValue === 'second') {
          this.productCenterImage = require('../assets/pod1.png');
        } else if (newValue === 'third') {
          this.productCenterImage = require('../assets/pood2.png');
        } else if (newValue === 'four') {
          this.productCenterImage = require('../assets/pood3.png');
        } else if (newValue === 'Five') {
          this.productCenterImage = require('../assets/pood5.png');
        }
     
      }
    }
  }

</script>

<style scoped>
  .page2 {
    background-color: #5B5B5B;
  }

  h1 {
    color: blue;
  }

  .div1 {
    background-image: url(../assets/PG6.png)
  }

  .div2 {
    background-image: url(../assets/PG7.png)
  }

  .div3 {
    background-image: url(../assets/PG8.png)
  }

  .div4 {
    background-image: url(../assets/PG9.png)
  }
  .contain /deep/ .t-tabs__nav-item.t-size-m {
    font-size: 18px;
    font-family: 'HarmonyOS_Sans_SC_Regular';
    font-weight: bold;
  }
</style>