<template>
    <div>
        <div style="width: 6.5104rem;margin: 0 auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/product-center'}">yth206官网首页</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/product-center', query: { tab: 'nine' }}">收费车道设备系列</el-breadcrumb-item>
                <el-breadcrumb-item>费额显示器</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="display: flex;justify-content: center;margin-top: .4167rem;">
            <div>
                <img src="../../assets/kbxx1.jpg" alt="" style="height: 2.3438rem;">
            </div>

            <div style="width:2.7344rem;margin-left: .2604rem;position: relative;">
                <p class="name">费额显示器</p>
                <P class="content" style="text-indent: .1198rem;font-size: .0833rem">费额显示器常用于省道、国道、高速公路收费站等交通控制场所，安装于收费站收费车道放行前方，指示车辆的收费、通行及报警信息。费额显示器采用全点阵方式显示，含多种显示方式和亮度调整，还可实现多种语音播报。
                </P>
                <div style="position: absolute;bottom: .1563rem;">
                    <div style="position: relative;
                    padding-left: .1302rem;
                    font-size: .0729rem;
                    color: #2056c0;">
                        <icon class="el-icon-phone-outline"></icon>技术热线
                    </div>
                    <div class="tel">181&nbsp;&nbsp;2248&nbsp;&nbsp;4161</div>

                </div>


            </div>



        </div>

        <div class="title" style="border-bottom: .0156rem solid #2056c0;width: 6.5104rem ;margin: 0 auto;margin-top: .1042rem; ">
            详细参数</div>

        <!--  style="width: 6.5104rem;margin: 0 auto;"-->
        <div class="msgList" style="width: 6.5104rem;margin: 0 auto;">
            <ul>
                <li>
                    <div class="txt"><em>型号</em></div>
                    <div class="msg">IF-F840/IF-F860/IF-870/IF-880</div>
                </li>
                <li>
                    <div class="txt"><em>像素点间距</em></div>
                    <div class="msg">4.75mm/6mm/7.62mm/8mm</div>
                </li>
                <li>
                    <div class="txt"><em>分辨率</em></div>
                    <div class="msg">128*64/96*64/144*72/144*72</div>
                </li>
                <li>
                    <div class="txt"><em>规格尺寸</em></div>
                    <div class="msg">608mm*304mm/576mm*384mm/1097mm*548mm/1152mm*576mm</div>
                </li>
                <li>
                    <div class="txt"><em>发光强度</em></div>
                    <div class="msg">＞1500cd/㎡/＞2000cd/㎡/＞2000cd/㎡/＞3500cd/㎡/</div>
                </li>
                <li>
                    <div class="txt"><em>显示方式</em></div>
                    <div class="msg">采用点阵显示方式，16*16、24*24、32*32国标宋体、黑金、楷体全点阵字库，显示方式多种可调，可以实现上下、左右滚屏显示</div>
                </li>
                <li>
                    <div class="txt"><em>机箱</em></div>
                    <div class="msg">黑色机箱（或不锈钢箱体），防水、防尘、防锈蚀，密封性IP65</div>
                </li>
                <li>
                    <div class="txt"><em>工作环境</em></div>
                    <div class="msg">温度-40℃~+65℃，湿度10%~95%RH</div>
                </li>
                <li>
                    <div class="txt"><em>输入电压</em></div>
                    <div class="msg">AC220V±10%，50±3Hz</div>
                </li>
                <li>
                    <div class="txt"><em>消耗功率</em></div>
                    <div class="msg">100W/150W/250W/350W</div>
                </li>
                <li>
                    <div class="txt"><em>控制方式</em></div>
                    <div class="msg">标准RS232或RS485，可实现多机通讯</div>
                </li>
                <li>
                    <div class="txt"><em>亮度调节</em></div>
                    <div class="msg">16级自适应亮度，以防止在夜间产生眩光；长期暴露于太阳光下，其可见性不会降低</div>
                </li>
                <!-- <li>
                    <div class="txt"><em>可视距离</em></div>
                    <div class="msg">≥30</div>
                </li> -->
            </ul>
        </div>




        <div style="display: flex;justify-content: center;margin-top: .1302rem;margin-bottom: .1302rem; ">
            <el-button @click="goBack">返回列表</el-button>
        </div>


    </div>




</template>
<script>


    export default {
        methods: {
            goBack() {
  this.$router.push({ path: '/product-center', query: { tab: 'nine' }});
  const windowHeight = window.innerHeight; // 获取窗口高度
                const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
                const scrollTo = targetOffsetTop - (windowHeight / 2); // 计算滚动距离
                window.scrollTo(0, scrollTo); // 滚动到目标位置

}
    
        
            ,

            //       goTo(){
            //     this.$router.push({ path: '/product-center', query: { tab: 'second' } });
            //     const windowHeight = window.innerHeight; // 
            //     const targetOffsetTop = 900; // 设置目标元素距离页面顶部的偏移量
            //     const scrollTo = targetOffsetTop - (windowHeight / 2); 
            //     window.scrollTo(0, scrollTo); //
            //   },
        }
    }




</script>

<style scoped>
    .name {
        padding-bottom: .1042rem;
        border-bottom: #eeeeee solid .0052rem;
        font-size: .1042rem;
        color: #2056c0;
        font-weight: bold;
        position: relative;
    }

    .msgList {
        padding-top: .2604rem;

        border-bottom: #eee solid .0052rem;
    }

    .msgList li {
        border: #c6c6c6 solid .0052rem;
        padding: .0521rem .1042rem .0521rem 1.0938rem;
        position: relative;
        font-size: .0729rem;
        margin-bottom: .0521rem;
        text-align: center;
        line-height: .2604rem;
    }

    li {
        list-style-position: outside;
        list-style-type: none;

    }

    .msgList li .msg {
        line-height: .1667rem;
        color: #999;
    }

    .content {
        padding-top: .1563rem;
        font-size: .0729rem;
        line-height: .125rem;
        color: #434343;

    }

    .tel {
        padding-top: .0521rem;
        font-size: .1875rem;
        color: #bb3944;
        font-family: Impact;
    }

    .txt {
        position: absolute;
        left: 0rem;
        top: 0rem;
        width: .9896rem;
        height: 100%;
        text-align: center;
        color: #FFFFFF;
        background: #2056c0;
        font-size: .0729rem;
        font-family: Arial, "微软雅黑";

    }

    .tittle {

        margin-top: .5208rem;
        height: .2083rem;
        text-align: center;
        line-height: .2083rem;
        font-weight: bold;
        font-size: .0833rem;
        color: #fff;

        position: relative;
    }
</style>