<template>

    <div>
  

  
    
      
  
      <div style="height:auto;display: flex; justify-content: center;align-items: center;margin-top: .2604rem;margin-bottom: .2604rem;">
  
        <div class="tab-container">
              <div style="display: flex;justify-content: center;">
                <div class="leftDiv fl">
  
                  <div class="name" style="font-size: .1042rem;
                      color: #2056c0;
                      padding-bottom: .0781rem;margin-top: .2604rem;"> yth2206游艇会手机版 </div>

                  <div class="tel" style=" font-weight:bold; font-size: .1875rem;
                      color: #bb3944;
                      padding-bottom: .1042rem;"> 020-82037620 
                  </div>

                  <ul style="list-style: none;font-size: .0729rem;">
                    <li>传真：020-34769066</li>
                    <li>邮箱：liuyuan@furhigh.com</li>
                    <li> 公司地址：广州市黄埔区开源大道11号B3栋101室、201室 </li>
                  </ul>
                </div>
  
                <!-- <img src="../assets/1478.png" alt=""> -->
  
                <baidu-map id="map" 
                  style="height: 1.5625rem; width: 4.1667rem;margin-bottom:.1042rem; margin-top: .0781rem; margin-left: .2604rem;" :scroll-wheel-zoom="true"
                  @ready="handler"></baidu-map>
              </div>
  

        </div>
  
  
      </div>
    </div>
  </template>
  
  <script>

    export default {
      data() {
        return {
          activeName: 'first',
          tableData: [{
            date: '2024-01-15',
            name: '软件工程师',
  
          }, {
            date: '2024-01-15',
  
            name: '硬件工程师'
          },
          {
            date: '2024-01-15',
  
            name: '销售工程师'
          }],
          tableData1: [{
            date: '2024-01-15',
            name: '应届毕业生',
  
          }, {
            date: '2024-01-15',
  
            name: '售后工程师'
          },
          {
            date: '2024-01-15',
  
            name: '嵌入式软件工程师'
          },
          {
            date: '2024-01-15',
  
            name: '硬件工程师'
          },
          {
            date: '2024-01-15',
  
            name: '高级软件工程师'
          }],
          pageTitle: "加入我们",
          positions: [
            {
              id: 1,
              title: "前端工程师",
              description: "负责开发和维护公司网站前端部分"
            },
            {
              id: 2,
              title: "后端工程师",
              description: "负责开发和维护公司网站后端部分"
            },
            {
              id: 3,
              title: "UI/UX设计师",
              description: "负责设计用户界面和用户体验"
            }
          ]
        };
      },
     
  watch: {
    '$route.query.tab': {
      immediate: true,
      handler(newTab) {
        this.handleTabChange(newTab);
      }
    }
  },
      mounted() {
        this.$nextTick(() => {
      window.scrollTo(0, 400); // 将页面滚动到5.2083rem的位置
    });
        // 在组件挂载完成后，将活动标签设置为第一个标签的name属性值
        this.handleTabChange(this.$route.query.tab);
        // const tab = this.$route.query.tab;
        // if (tab === 'first') {
        //   this.activeName = 'first'; // 设置第一个选项卡为激活状态
        // } else if (tab === 'second') {
        //   this.activeName = 'second'; // 设置第二个选项卡为激活状态
        // } else if (tab === 'third') {
        //   this.activeName = 'third'; // 设置第三个选项卡为激活状态
        // } else if (tab === 'four') {
        //   this.activeName = 'four'; // 设置第三个选项卡为激活状态
        // }
        /* eslint-disable */
  
        const map = new BMap.Map('map');
    map.centerAndZoom(new BMap.Point(113.502062,23.158557), 14);
    map.enableScrollWheelZoom(true);
  
    const data_info = [
      [113.502062,23.158557, "地址：广州市黄埔区开源大道11号B3栋101室、201室"]
    ];
    const opts = {
      width: 260,
      height: 80,
      title: "yth2206游艇会手机版",
      enableMessage: true,
      enableCloseOnClick: true, // 点击信息窗口内容是否可以关闭信息窗口
      enableAutoPan: true, // 是否自动平移地图以让信息窗口完全显示
      enableContentHtml: true, // 是否允许信息窗口内容可以包含HTML标签
      content: "", // 信息窗口内容
      contentStyle: {
        fontSize: ".0833rem" // 设置信息窗口字体大小为.0833rem
      }
    };
  
    for (let i = 0; i < data_info.length; i++) {
      const marker = new BMap.Marker(new BMap.Point(data_info[i][0], data_info[i][1]));
      const content = data_info[i][2];
      map.addOverlay(marker);
      addClickHandler(content, marker);
    }
  
    const location = new BMap.Point(113.502062,23.158557); // 定位的位置坐标
    const locationIcon = new BMap.Icon('https://api.map.baidu.com/images/marker_red.png', new BMap.Size(25, 35)); // 标记点的图标，可以根据需要修改图标样式和大小
    const locationMarker = new BMap.Marker(location, { icon: locationIcon }); // 创建标记点并设置图标
    map.addOverlay(locationMarker); // 添加标记点到地图中
  
    // 获取定位标记点的像素坐标
    const pixel = map.pointToPixel(location);
  
    // 将地图中心设置为定位标记点的坐标
    map.setCenter(map.pixelToPoint(new BMap.Pixel(pixel.x -6, pixel.y  -8)));
  
    function addClickHandler(content, marker) {
      marker.addEventListener("click", function (e) {
        openInfo(content, e);
      });
    }
  
    function openInfo(content, e) {
      const p = e.target;
      const point = new BMap.Point(p.getPosition().lng, p.getPosition().lat);
      const infoWindow = new BMap.InfoWindow(content, opts);
      map.openInfoWindow(infoWindow, point);
    }
        /* eslint-enable */
  
  
  
  
  
  
  
  
      },
      methods: {
        handleTabChange(tab) {
      if (tab === 'first') {
        this.activeName = 'first'; // 设置第一个选项卡为激活状态
      } else if (tab === 'second') {
        this.activeName = 'second'; // 设置第二个选项卡为激活状态
      }else if (tab === 'third') {
        this.activeName = 'third'; // 设置第二个选项卡为激活状态
      }
      else if (tab === 'four') {
        this.activeName = 'four'; // 设置第二个选项卡为激活状态
      }
      console.log(tab); // 打印传递过来的 tab 参数
    },
  
  
  
  
      }
    };
  </script>
  <style scoped>
    .about-us {
      max-width: 6.25rem;
      margin: 0 auto;
      padding: .2083rem;
    }
  
    .banner {
      text-align: center;
      margin-bottom: .2083rem;
    }
  
    .banner-title {
      font-size: .1875rem;
      font-weight: bold;
      color: #333;
    }
  
    .banner-image {
      width: 100%;
      max-height: 2.0833rem;
      object-fit: cover;
      border-radius: .0417rem;
    }
  
    .content {
      margin-bottom: .2083rem;
    }
  
    .section {
      margin-bottom: .1042rem;
    }
  
    .section-title {
      font-size: .125rem;
      font-weight: bold;
      color: #333;
    }
  
    .section-text {
      font-size: .0833rem;
      color: #555;
    }
  
    .team-title {
      font-size: .125rem;
      font-weight: bold;
      color: #333;
      margin-bottom: .1042rem;
    }
  
    .team-members {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .team-member {
      display: flex;
      align-items: center;
      margin: .0521rem;
    }
  
    .item-container {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          position: relative;
      }
  
      .opp /deep/ .el-tabs__nav-scroll {
          background-color: #E9E9E9;
  
      }
  
      .frp {
          background-color: #E9E9E9;
          width: .1042rem;
          height: .1042rem;
      }
  
      .title-container {
          flex-basis: 100%;
      }
  
      .item-title {
          /* 标题样式 */
      }
  
      .opp /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
          border-right-color: #407BF5;
      }
  
      .opp /deep/ .el-tabs--border-card {
          border: none;
          box-shadow: none;
      }
  
      .item-image {
          margin-left: auto;
      }
  
      .item-container::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: 100%;
  
      }
  
    .member-avatar {
      width: .4167rem;
      height: .4167rem;
      object-fit: cover;
      border-radius: 50%;
    }
  
    .member-details {
      margin-left: .0521rem;
    }
  
    .member-name {
      font-size: .0833rem;
      font-weight: bold;
      color: #333;
    }
  
    .member-role {
      font-size: .0729rem;
      color: #555;
    }
  
    .year {
  
  
      height: .2083rem;
      line-height: .2083rem;
      font-size: .0833rem;
      border-bottom: #eee solid .0052rem;
      width: .8854rem;
      padding-left: .125rem;
    }
  
    .year b {
      font-size: .1563rem;
      color: #bb3944;
      font-weight: bold;
    }
  
    .el-tabs__item {
      width: 1.5625rem;
      /* 设置每个el-table-pane的宽度 */
    }
  
    .el-tabs.el-tabs--top {
      width: 6.25rem;
    }
  
    #tab-first .el-tabs__item.is-top {
      width: 1.5625rem;
    }
  </style>